/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}
body {
    max-width: 1920px;
    margin: 0 auto;
}
li {
    list-style: none;
}
.center {
    width: 1200px;
    margin: 0 auto;
}

.yu_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.header {
    width: 100%;
    position: relative;
    .h_top {
        width: 100%;
        height: 35px;
        position: relative;
        line-height: 35px;
        .yu_bg {
            background: #e3e3e3;
        }
        .text {
            span {
                float: left;
                font-size: 14px;
                color: #999;
            }
            p {
                float: right;
                font-size: 14px;
                color: #999;
                padding-left: 18px;
                background: url(../img/ic1.png) no-repeat left center;
                i {
                    font-style: normal;
                    font-weight: bold;
                }
            }
        }
    }
    .h_midder {
        padding: 15px 0;
        position: relative;
        .yu_bg {
            background: #fff;
        }
        .text {
            .fl {
                float: left;
                img {
                    display: block;
                }
            }
            .fr {
                float: right;
                margin-top: 15px;
                p {
                    float: left;
                    font-size: 16px;
                    color: #999;
                    line-height: 50px;
                    margin-left: 40px;
                    padding-left: 45px;
                    border-right: 1px solid #999;
                    padding-right: 20px;
                    &:first-child {
                        background: url(../img/map.png) no-repeat left center;
                    }
                    &:first-child +p {
                        background: url(../img/tel.png) no-repeat left center;
                        margin-left: 25px;
                    }
                    &:first-child +p +p {
                        background: url(../img/time.jpg) no-repeat left center;
                    }
                    &:last-child {
                        padding-right: 0;
                        border: none;
                    }
                    span {
                        display: block;
                        line-height: 25px;
                    }
                }
            }
        }
    }
    .nav {
        position: relative;
        .yu_bg {
            background: #000;
        }
        .h_nav {
            width: 100%;
            height: 56px;
            ul {
                li {
                    height: 56px;
                    width: 12.5%;
                    float: left;
                    line-height: 56px;
                    position: relative;
                    text-align: center;
                    >a {
                        color: #fff;
                        font-size: 16px;
                        line-height: 56px;
                        text-align: center;
                        display: block;
                        &.active {
                            background: #fff;
                            color: #333;
                        }
                    }
                    .v_list {
                        position: absolute;
                        width: 100%;
                        top: 56px;
                        background: #000;
                        left: 0;
                        z-index: 99;
                        display: none;
                        a {
                            width: 100%;
                            color: #fff;
                            line-height: 36px;
                            font-size: 14px;
                            transition: .5s;
                            text-align: center;
                            display: block;
                            &:hover {
                                background: #fff;
                                color: #000;
                            }
                        }
                    }
                    &:hover >a {
                        background: #fff;
                        color: #000;
                    }
                }
            }
        }
    }
}

.pc_banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    .pcbanner {
        .swiper-slide {
            img {
                width: 100%;
                display: block;
            }
        }
        .pagination {
            text-align: center;
            position: absolute;
            bottom: 10px;
            width: 100%;
            z-index: 99;
            span {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: #eee;
                margin: 0 3px;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #000;
                    color: #fff;
                }
            }
        }
        .next {
            position: absolute;
            right: 5%;
            top: 45%;
            z-index: 99;
            height: 45px;
            width: 45px;
            background: url(../img/ri.png);
            opacity: 1;
            background-size: 100%;
            cursor: pointer;
            outline: 0;
            background-size: 100% 100%;
            &:hover {
                background: url(../img/hri.png);
                background-size: 100% 100%;
            }
        }
        .prev {
            position: absolute;
            left: 5%;
            top: 45%;
            z-index: 99;
            height: 45px;
            width: 45px;
            background: url(../img/le.png);
            opacity: 1;
            background-size: 100%;
            cursor: pointer;
            outline: 0;
            background-size: 100% 100%;
            &:hover {
                background: url(../img/hle.png);
                background-size: 100% 100%;
            }
        }
    }
}

.m_header {
    width: 100%;
    overflow: hidden;
    display: none;
    border-top: 3px solid #000;
    .m_head_logo {
        height: 70px;
        padding-left: 10px;
        position: relative;
        .yu_bg {
            background: #fff;
        }
        a {
            float: left;
            img {
                height: 60px;
                display: block;
                margin: 0 auto;
                margin-top: 5px;
            }
        }
    }
}

.app_menu {
    width: 100%;
    position: relative;
    .yu_bg {
        background: #000;
    }
    ul {
        li {
            width: 25%;
            float: left;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            line-height: 42px;
            font-size: 12px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            &:nth-child(4n) {
                border-right: none;
            }
            &:hover {
                background: #fff;
                color: #000;
            }
            &.on {
                background: #fff;
                color: #000;
            }
        }
    }
}

.m_banner {
    display: none;
    width: 100%;
    overflow: hidden;
    .mbanner {
        position: relative;
        .swiper-slide {
            img {
                width: 100%;
                display: block;
            }
        }
        .pagination {
            text-align: center;
            position: absolute;
            bottom: 5px;
            width: 100%;
            z-index: 90;
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: none;
                border: 1px solid #fff;
                margin: 0 4px;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #fff;
                    color: #fff;
                }
            }
        }
    }
}

.hot_search {
    position: relative;
    .yu_bg {
        background: #e3e3e3;
    }
    .text {
        p {
            line-height: 42px;
            span {
                float: left;
                font-size: 16px;
                color: #333;
                font-weight: bold;
            }
            a {
                float: left;
                font-size: 12px;
                color: #333;
                margin-left: 12px;
                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
}

.news_hot {
    padding: 20px 0;
    .list {
        position: relative;
        height: 50px;
        padding-left: 95px;
        padding-right: 40px;
        .yu_bg {
            background: #000;
            border-radius: 5px;
        }
        .tit {
            position: absolute;
            left: 0;
            top: 0;
            width: 95px;
            span {
                line-height: 50px;
                font-size: 15px;
                color: #fff;
                text-align: center;
                display: block;
            }
        }
        .news_left {
            .bd {
                li {
                    width: 50%;
                    float: left;
                    line-height: 50px;
                    span {
                        font-size: 14px;
                        color: #fff;
                        margin-right: 20px;
                    }
                    a {
                        font-size: 14px;
                        color: #fff;
                        padding-left: 15px;
                        background: url(../img/dian.gif) no-repeat left center;
                    }
                }
            }
        }
    }
}

.index_box {
    width: 100%;
    overflow: hidden;
    padding-bottom: 25px;
    position: relative;
}

.yu_tit {
    margin: 20px 0;
    p {
        text-align: center;
        span {
            display: inline-block;
            padding-bottom: 2px;
            font-size: 28px;
            color: #000;
            border-bottom: 3px solid #000;
        }
    }
}

.yu_catlist {
    li {
        float: left;
        width: 190px;
        margin-right: 12px;
        height: 40px;
        line-height: 40px;
        a {
            width: 100%;
            display: block;
            text-align: center;
            cursor: pointer;
            list-style-type: none;
            font-size: 14px;
            border-radius: 2px;
            overflow: hidden;
            border: 1px solid #000;
            background: #fff;
            &:hover {
                background: #000;
                color: #fff;
            }
        }
    }
}

.in_pro {
    margin-top: 20px;
    position: relative;
    .pro_next {
        position: absolute;
        left: -5%;
        top: 36%;
        z-index: 99;
        width: 30px;
        height: 50px;
        background: url(../img/l1.jpg);
        opacity: .5;
        background-size: 100%;
        cursor: pointer;
        outline: 0;
        background-size: 100% 100%;
        &:hover {
            opacity: 1;
        }
    }
    .pro_prev {
        position: absolute;
        right: -5%;
        top: 36%;
        z-index: 99;
        width: 30px;
        height: 50px;
        background: url(../img/r1.jpg);
        opacity: .5;
        background-size: 100%;
        cursor: pointer;
        outline: 0;
        background-size: 100% 100%;
        &:hover {
            opacity: 1;
        }
    }
    .swiper-slide {
        position: relative;
        a {
            .imgauto {
                width: 100%;
                height: 215px;
                img {
                    transform: scale(1);
                    transition:.5s;
                }
            }
            span {
                width: 100%;
                background: #000;
                position: absolute;
                left: 0;
                bottom: -30px;
                height: 30px;
                font-size: 14px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                transition:.5s;
            }
        }
        &:hover {
            a {
                .imgauto {
                    img {
                        transform: scale(1.2);
                        transition:.5s;
                    }
                }
                span {
                    bottom: 0;
                    transition:.5s;
                }
            }
        }
    }
}

.in_more {
    text-align: center;
    margin-top: 20px;
    a {
        display: inline-block;
        padding-left: 50px;
        padding-right: 50px;
        line-height: 40px;
        font-size: 15px;
        border-radius: 4px;
        border: #ddd solid 1px;
        background: #fff;
        color: #666;
        transition: .5s;
        &:hover {
            background: #000;
            color: #fff;
            transition: .5s;
            border: #000 solid 1px;
        }
    }
}

.in_about {
    position: relative;
    .yu_bg {
        background: #fff;
    }
    .fl {
        width: 55%;
        padding-right: 10px;
        span {
            font-size: 18px;
            color: #666;
            display: block;
            letter-spacing: 8px;
            line-height: 60px;
        }
        p {
            color: #888;
            font-size: 16px;
            line-height: 28px;
            text-indent: 28px;
            margin-bottom: 10px;
        }
        .in_more {
            text-align: left;
            padding-top: 10px;
            a {
                padding: 0 30px;
                line-height: 32px;
                font-size: 14px;
                color: #aaa;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    .fr {
        width: 40%;
        img {
            margin-top: 15px;
            width: 100%;
            display: block;
        }
    }
}

.in_case {
    margin-top: 20px;
    position: relative;
    .yu_bg {
        background: #fff;
    }
    li {
        width: 23%;
        float: left;
        border: 1px solid #ddd;
        margin-right: 2.66%;
        margin-bottom: 2.66%;
        padding: 9px;
        .pic {
            position: relative;
            .imgauto {
                width: 100%;
                height: 200px;
                img {
                    transform: scale(1);
                    transition:.5s;
                }
            }
            span {
                display: none;
            }
        }
        .libg {
            position: absolute;
            left: 50%;
            top: 50%;
            background: rgba(0,0,0,.5);
            width: 0;
            height: 0;
            transition: .5s;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:hover {
            box-shadow: 0px 1px 4px #999;
            .pic {
                .imgauto {
                    img {
                        transform: scale(1.2);
                        transition:.5s;
                    }
                }
            }
            .libg {
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: .5s;
            }
        }
    }
}

.in_news {
    padding-top: 20px;
    position: relative;
    .yu_bg {
        background: #fff;
    }
    li {
        width: 46%;
        margin-top: 20px;
        margin-bottom: 20px;
        border: #fff solid 2px;
        background: #F5F6F8;
        padding: 13px;
        float: left;
        margin-right: 40px;
        border: 2px solid #F5F6F8;
        .pic {
            width: 36%;
            float: left;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                transform: scale(1);
                transition:.5s;
            }
        }
        .text {
            float: left;
            width: 64%;
            padding-left: 10px;
            a {
                font-size: 18px;
                color: #333;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 30px;
            }
            p {
                text-indent: 2em;
                line-height: 25px;
                height: 100px;
                overflow: hidden;
                font-size: 14px;
                color: #666;
            }
        }
        &:hover {
            border: 2px solid #000;
            .pic {
                img {
                    transform: scale(1.15);
                    transition:.5s;
                }
            }
        }
    }
}

.links {
    p {
        font-size: 14px;
        color: #fff;
        line-height: 30px;
        span {
            float: left;
            font-weight: bold;
        }
        a {
            float: left;
            margin-right: 8px;
        }
    }
}

.foot_nav {
    p {
        font-size: 14px;
        color: #fff;
        line-height: 35px;
        span {
            float: left;
            font-weight: bold;
        }
        a {
            float: left;
            line-height: 100%;
            font-size: 14px;
            margin-top: 10.5px;
            padding-right: 8px;
            margin-right: 8px;
            border-right: 1px solid #ddd;
            &:last-child {
                border: none;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.foot_text {
    padding-top: 8px;
    padding-bottom: 10px;
    p {
        font-size: 14px;
        color: #fff;
        line-height: 25px;
        margin-bottom: 2px;
    }
}

.footer {
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
    position: relative;
    .yu_bg {
        background: #646464;
    }
    .fl {
        width: 70%;
    }
    .fr {
        width: 280px;
        margin-top: 25px;
        img {
            width: 145px;
            display: block;
            margin-right: 43px;
            float: left;
        }
        span {
            float: left;
            width: 80px;
            display: block;
            font-size: 14px;
            color: #fff;
            line-height: 30px;
            margin-top: 45px;
        }
    }     
}

.mtfix {
    margin-top: 72px;
    overflow: hidden;
    display: none;
}

.fix_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    display: none;
    .yu_bg {
        background: #000;
    }
    a {
        width: 25%;
        height: 62px;
        float: left;
        text-align: center;
        padding-top: 4px;
        border-left: 1px solid #aaa;
        img {
            display: block;
            margin: 0 auto;
            height: 28px;
        }
        span {
            line-height: 30px;
            color: #fff;
            display: block;
            text-align: center;
        }
        &:first-child {
            border: none;
        }
    }
}

.currson {
    position: relative;
    border-bottom: #dddede 1px solid;
    .yu_bg {
        background: #f9f9f9;
    }
    .text {
        line-height: 42px;
        p {
            width: 100%;
            float: left;
            padding-left: 42px;
            background: url(../img/home.png) no-repeat 12px center;
            border-left: #dddede 1px solid;
            span {
                float: left;
                border-left: #dddede 1px solid;
                padding-left: 12px;
                color: #333;
            }
            a {
                float: left;
            }
        }
    }
}

.page_tit {
    padding-top: 40px;
    padding-bottom: 30px;
    position: relative;
    .yu_bg {
        background: #fff;
    }
    span {
        font-size: 24px;
        color: #000;
        display: block;
        text-align: center;
        font-weight: bold;
        line-height: 45px;
    }
}

.page_nav {
    position: relative;
    .yu_bg {
        background: #ececec;
    }
    .list {
        text-align: center;
        a {
            line-height: 50px;
            padding: 0 20px;
            @include inline-block();
            color: #111;
            font-size: 15px;
            outline: 0;
            &:hover {
                background: #000;
                color: #fff;
            }
            &.on {
                background: #000;
                color: #fff;
            }
        }
    }
}

.artlist {
    padding-top: 10px;
    position: relative;
    .yu_bg {
        background: #fff;
    }
    li {
        padding: 15px 0;
        float: left;
        border-bottom: #dddddd dotted 1px;
        .time {
            float: left;
            width: 76px;
            background: #ececec;
            text-align: center;
            padding: 14px 0;
            p {
                font-size: 24px;
                font-weight: bold;
                display: block;
                text-align: center;
                color: #000;
            }
            span {
                display: block;
                text-align: center;
                font-size: 14px;
                color: #666;
            }
        }
        .text {
            margin: 0 10px 0 95px;
            a {
                font-size: 16px;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 26px;
            }
            p {
                font-size: 12px;
                color: #999;
                display: block;
                line-height: 22px;
                height: 44px;
                overflow: hidden;
                margin-top: 8px;
            }
        }
    }
}

.m_page_menu {
    position: relative;
    display: none;
    .text {
        height: 35px;
        position: relative;
        .yu_bg {
            background: #ececec;
        }
        .m_page_tit {
            float: left;
            span {
                width: 115px;
                background: #000;
                display: block;
                line-height: 35px;
                font-size: 14px;
                color: #fff;
                padding: 0 15px;
                text-align: right;
                font-weight: bold;
                i {
                    float: left;
                    background: #fff;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: url(../img/ri2.png) no-repeat center #fff;
                    background-size: 5px;
                    margin-top: 10.5px;
                }
            }
        }
        .return {
            float: right;
            margin-right: 12px;
            a {
                font-size: 13px;
                color: #666;
                display: block;
                line-height: 35px;
            }
        }
        .pagemenu {
            float: right;
            span {
                width: 36px;
                height: 24px;
                margin-top: 5px;
                margin-right: 12px;
                background: #000;
                display: block;
                border-radius: 6px;
                padding-top: 4px;
                img {
                    width: 18px;
                    height: 16px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
    .m_menulist {
        position: absolute;
        right: 0;
        top: 35px;
        border: 1px solid #666;
        display: none;
        z-index: 99;
        li {
            width: 200px;
            background: #000;
            line-height: 38px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            border-bottom: 1px solid #666;
            a {

            }
        }
    }
}

.content_wrap {
    position: relative;
    .yu_bg {
        background: #fff;
    }
}

.content_tit {
    h3 {
        font-size: 16px;
        color: #000;
        line-height: 1.7em;
        text-align: center;
        margin-bottom: 10px;
    }
    p {
        width: 100%;
        text-align: center;
        padding: 6px 15px;
        border: 1px dashed #ddd;
        border-left: none;
        border-right: none;
        span {
            line-height: 21px;
            font-size: 12px;
            color: #666;
            margin: 0 5px;
        }
    }
}

.content {
    p {
        font-size: 14px;
        line-height: 24px;
        text-indent: 2em;
        margin-top: 15px;
    }
    img {
        display: block;
        margin: 0 auto;
    }
}

.show_pages {
    padding-top: 20px;
    p {
        font-size: 14px;
        line-height: 24px;
        color: #333;
        margin-top: 6px;
        a {
            font-size: 14px;
            line-height: 24px;
            color: #333;
        }
    }
}

.re_new {
    margin-top: 20px;
    .re_tit {
        height: 40px;
        background: #000;
        span {
            padding-left: 30px;
            background: url(../img/share.png) no-repeat left center;
            background-size: 22px;
            display: block;
            line-height: 40px;
            font-size: 16px;
            color: #fff;
            margin-left: 20px;
        }
    }
    .show_newlist {
        margin-top: 12px;
        li {
            width: 48%;
            margin-right: 4%;
            float: left;
            border-bottom: 1px dotted #ddd;
            line-height: 32px;
            a {
                font-size: 14px;
                color: #333;
                padding: 0 15px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}

.pro_box {
    position: relative;
    .yu_bg {
        background: #fff;
    }
}

.pro_list {
    padding-top: 20px;
    li {
        width: 23%;
        float: left;
        margin-right: 2.66%;
        .pic {
            position: relative;
            box-shadow: 0px 2px 6px #777;
            border: 1px solid #ddd;
            .imgauto {
                width: 100%;
                height: 207px;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            .libg {
                position: absolute;
                left: 50%;
                top: 50%;
                background: url(../img/ic2.png) no-repeat center rgba(0,0,0,.5);
                width: 0;
                height: 0;
                transition: .5s;
                overflow: hidden;
                transform: rotateY(-180deg);
                -moz-transform: rotateY(-180deg);
                -ms-transform: rotateY(-180deg);
                a {
                    display: block;
                    height: 100%;
                }
            }
        }
        span {
            font-size: 14px;
            line-height: 60px;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #666;
            text-align: center;
            display: block;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:hover {
            .pic {
                .imgauto {
                    img {
                        transform: scale(1.2);
                        transition: .5s;
                    }
                }
                .libg {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    transition: .5s;
                    transform: rotateY(0deg);
                    -moz-transform: rotateY(0deg);
                    -ms-transform: rotateY(0deg);
                }
            }
        }
    }
}

.pro_showbox {
    position: relative;
    border: #dddddd 1px solid;
    padding: 10px;
    .yu_bg {
        background: #f1f1f1;
        z-index: 0;
    }
    .show_page_pro {
        position: relative;
        width: 100%;
        border: #dddddd 1px solid;
        padding: 10px;
        .yu_bg {
            background: #fff;
            z-index: 1;
        }
        .pic {
            position: relative;
            z-index: 2;
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
        .showfl {
            position: absolute;
            left: 10px;
            top: 0;
            width: 25px;
            height: 100%;
            z-index: 2;
            a {
                width: 100%;
                height: 69px;
                background: url(../img/le3.png) no-repeat center;
                @include inline-block();
            }
        }
        .showfr {
            position: absolute;
            right: 10px;
            top: 0;
            width: 25px;
            height: 100%;
            z-index: 2;
            a {
                width: 100%;
                height: 69px;
                background: url(../img/ri3.png) no-repeat center;
                @include inline-block();
            }
        }
    }
    .pro_wrap {
        position: relative;
        margin-top: 12px;
        width: 100%;
        border: #dddddd 1px solid;
        padding: 10px;
        .yu_bg {
            background: #fff;
            z-index: 1;
        }
        .shwopro_tit {
            position: relative;
            z-index: 2;
            span {
                font-size: 16px;
                color: #000;
                line-height: 1.7em;
                text-align: center;
                margin-bottom: 10px;
                text-align: center;
                display: block;
            }
        }
        .shwopro_pic {
            position: relative;
            z-index: 2;
            img {
                display: block;
                margin: 0 auto;
            }
        }
        .content {
            position: relative;
            z-index: 2;
        }
        .shwopro_time {
            position: relative;
            z-index: 2;
            margin-top: 30px;
            p {
                text-align: right;
                font-size: 14px;
                color: #666;
            }
        }
    }
}

.iet {
    height: 30px;
    width: 100%;
    background: #fff3d7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
        line-height: 30px;
        a {
            color: #3aba50;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        right: 5%;
        top: 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        i {
            font-size: 12px;
            color: #333;
            padding-left: 5px;
            font-style: normal;
        }
    }
}



@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
}
@media screen and (max-width: 1020px){
    .header {
        display: none;
    }
    .m_header {
        display: block;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .in_news {
        li {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px){
    .hot_search {
        .text {
            padding: 0 2%;
        }
    }

    .news_hot {
        padding: 10px 1%;
        .list {
            .news_left {
                .bd {
                    li {
                        width: 150%;
                    }
                }
            }
        }
    }
    
    .yu_tit {
        margin: 12px 0;
        p {
            span {
                font-size: 20px;
            }
        }
    }
   
    .index_box {
        padding: 0 2%;
        padding-bottom: 20px;
    }

    .in_more {
        a {
            padding: 0 36px;
            line-height: .28rem;
        }
    }

    .in_pro {
        margin-top: 10px;
        .swiper-slide {
            padding: 9px;
            border: 1px solid #ddd;
            a {
                display: block;
                .imgauto {
                    height: 1rem;
                }
                span {
                    position: relative;
                    text-align: center;
                    display: block;
                    bottom: 0;
                    background: none;
                    color: #999;
                    line-height: 24px;
                    margin-top: 8px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding: 0 2%;
                }
            }
        }
        .pro_next {
            display: none;
        }
        .pro_prev {
            display: none;
        }
    }

    .in_about {
        .fl {
            width: 100%;
            padding: 0;
            padding-top: 10px;
            span {
                display: none;
            }
            p {
                font-size: 14px;
                line-height: 24px;
            }
            .in_more {
                text-align: center;
                margin-top: 12px;
            }
        }
        .fr {
            width: 100%;
        }
    }

    .in_case {
        margin-top: 10px;
        li {
            width: 48.5%;
            padding: 9px;
            border: 1px solid #ddd;
            margin-right: 3%;
            margin-bottom: 3%;
            .pic {
                display: block;
                .imgauto {
                    height: 1rem;
                }
                span {
                    position: relative;
                    text-align: center;
                    display: block;
                    bottom: 0;
                    background: none;
                    color: #999;
                    line-height: 24px;
                    margin-top: 8px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding: 0 2%;
                }
            }
            .libg {
                display: none;
            }
            &:nth-child(2n){
                margin-right: 0;
            }
        }
        .pro_next {
            display: none;
        }
        .pro_prev {
            display: none;
        }
    }

    .footer {
        padding: 15px 2%;
        .yu_bg {
            border-radius: 8px;
        }
        .fl {
            width: 100%;
        }
        .fr {
            display: none;
        }
    }
    .currson {
        display: none;
    }
    .page_nav {
        display: none;
    }
    .page_tit {
        display: none;
    }
    .m_page_menu {
        display: block;
    }
    .pro_list {
        li {
            width: 31.33%;
            margin: 0 1%;
        }
    }


}

@media screen and (max-width: 450px){
    .hot_search {
        .text {
            p {
                height: 42px;
                overflow: hidden;
                span {
                    font-size: 15px;
                }
            }
        }
    }

    .news_hot {
        .list {
            .news_left {
                .bd {
                    li {
                        a {
                            font-size: 12px;
                        }
                        span {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .yu_catlist {
        li {
            width: 31.33%;
            margin: 5px 1%;
            height: .28rem;
            line-height: .28rem;
        }
    }

    .in_news {
        padding-top: 12px;
        li {
            margin: 10px 0;
            padding: 10px;
            .text {
                a {
                    font-size: .12rem;
                }
                p {
                    font-size: .1rem;
                    height: 66px;
                    line-height: 22px;
                }
            }
        }
    }

    .mtfix {
        display: block;
    }

    .fix_footer {
        display: block;
    }

    .re_new {
        .show_newlist {
            li {
                width: 100%;
            }
        }
    }

    .pro_list {
        padding: 0 2%;
        margin-top: 12px;
        li {
            width: 49%;
            margin: 0;
            margin-right: 2%;
            .pic {
                .imgauto {
                    height: 1.13rem;
                }
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }

}

@media screen and (max-width: 350px){
    .artlist {
        li {
            .time {
                width: 65px;
            }
            .text {
                margin: 0 5px 0 75px;
            }
        }
    }
}



/****************************** media ******************************/
